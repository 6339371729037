<template>
  <!-- Loading circle -->
  <div
    v-if="loading"
    class="d-flex justify-center"
  >
    <v-progress-circular
      :size="50"
      indeterminate
      color="primary"
    >
    </v-progress-circular>
  </div>
  <v-container
    v-else
  >
    <div
      v-if="eventsList.length === 0"
      style="height: 40vh"
      class="align-center justify-center d-flex"
    >
      <v-row
        align="center"
        justify="center"
      >
        <div>
          <div class="mt-6 align-center justify-center text-center text-2xl">
            🥳
          </div>
          <div
            class="mt-2 align-center justify-center text-center"
            style="word-break: break-word"
            :class="$vuetify.breakpoint.mdAndDown ? 'text-lg':'text-h5'"
          >
            {{ $t('thanksForChoosingUs') }}
          </div>
          <div
            class="align-center justify-center text-center"
            style="word-break: break-word"
            :class="$vuetify.breakpoint.mdAndDown ? 'text-base':'text-lg'"
          >
            {{ $t('createYourExcitingEvent') }}
          </div>
          <div
            class="mt-8 text-center"
          >
            <v-btn
              :to="'/create-event'"
              color="primary"
              rounded
              block
              @click="loading = true"
            >
              {{ $t('NewEvent') }}
            </v-btn>
          </div>
        </div>
      </v-row>
    </div>
    <div
      v-if="eventsList.length > 0"
      class="pt-0"
    >
      <!-- Create new event -->
      <v-btn
        :to="'/create-event'"
        color="primary"
        rounded
        @click="loading = true"
      >
        {{ $t('NewEvent') }}
      </v-btn>

      <!-- Cards for events -->
      <v-row>
        <v-col
          v-for="event in eventsList"
          :key="event.id"
          md="4"
          sm="6"
          cols="12"
          class="align-self-start pt-8"
        >
          <v-hover v-slot="{hover}">
            <v-card
              exact
              tile
              :elevation="hover? 12:0"
              :class="{'on-hover': hover}"
              class="bottom-0 pb-5/6 d-flex flex-column"
              @click="getEventDetails(event.id), loading = true"
            >
              <!-- Delete event option -->
              <v-menu
                top
                right
                offset-x
                offset-y
                transition="scale-transition"
                origin="center center"
              >
                <template
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    icon
                    class="verticalDots"
                    v-bind="attrs"
                    v-on="on"
                    @click.prevent
                  >
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>
                <!-- Delete event -->
                <v-list>
                  <v-card
                    width="110"
                    flat
                  >
                    <v-list-item class="ml-4">
                      <v-dialog
                        v-model="deleteQA"
                        width="400"
                      >
                        <template #activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            icon
                            v-on="on"
                          >
                            <v-icon
                              size="20"
                            >
                              {{ icons.mdiDeleteOutline }}
                            </v-icon>

                            <span>{{ $t('Delete') }}</span>
                          </v-btn>
                        </template>

                        <v-card
                          height="100%"
                          flat
                          class="rounded-xl text-center"
                        >
                          <v-card-title
                            class="justify-center align-center mb-1"
                          >
                            <v-icon
                              color="primary"
                              right
                              size="40"
                            >
                              {{ icons.mdiAlertCircleOutline }}
                            </v-icon>
                          </v-card-title>
                          <v-card-subtitle
                            class="text-xl pb-0"
                            style="word-break: break-word"
                          >
                            {{ $t('messageBeforeDelete') }}
                          </v-card-subtitle>
                          <v-card-text
                            class="text-center"
                            style="word-break: break-word"
                          >
                            {{ event.names[$i18n.locale] || event.names[event.defaultLang] }}
                          </v-card-text>

                          <v-card-actions
                            class="mt-6"
                          >
                            <v-flex>
                              <v-btn
                                color="primary"
                                class="me-3"
                                rounded
                                @click="delEvent(event.id)"
                              >
                                {{ $t('Ok') }}
                              </v-btn>
                              <v-btn
                                color="primary"
                                outlined
                                rounded
                                @click="deleteQA = false"
                              >
                                {{ $t('Cancel') }}
                              </v-btn>
                            </v-flex>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-list-item>
                  </v-card>
                </v-list>
              </v-menu>
              <!-- Event Image display -->
              <v-img
                class="white--text align-end"
                :src="event.image"
                height="250"
              >
              </v-img>

              <!-- Event details -->
              <v-card-text class="position-relative">
                <div class="d-flex justify-space-between flex-wrap pt-3">
                  <div class="me-2 mb-0">
                    <v-card-title
                      class="pt-0 px-0"
                      style="word-break: break-word"
                    >
                      {{ event.names[$i18n.locale] || event.names[event.defaultLang] }}
                    </v-card-title>
                    <v-card-subtitle class="text-s pa-0">
                      {{ event.location.eventLocationName }}
                    </v-card-subtitle>
                  </div>
                </div>

                <!-- event statistic -->
                <div
                  class="me-2 v-avatar-group pt-4 justify-end"
                  :class="rootThemeClasses"
                >
                  <div class="mx-2 rounded-pill">
                    <v-badge
                      overlap
                      :content="event.guestsComing"
                      offset-x="45"
                      offset-y="7"
                    >
                      <!-- Attending avatar -->
                      <v-avatar
                        size="40"
                        class="lightGrey success--text "
                      >
                        <v-icon
                          color="success"
                          size="25"
                        >
                          {{ icons.mdiAccount }}
                        </v-icon>
                      </v-avatar>
                    </v-badge>
                  </div>
                  <div class="rounded-pill">
                    <v-badge
                      overlap
                      :content="event.guestsNotComing"
                      offset-x="45"
                      offset-y="7"
                    >
                      <!-- Not attending avatar  -->
                      <v-avatar
                        size="40"
                        class="lightGrey error--text"
                      >
                        <v-icon
                          color="error"
                          size="25"
                        >
                          {{ icons.mdiAccount }}
                        </v-icon>
                      </v-avatar>
                    </v-badge>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { i18n } from '@/plugins/i18n/index'
// eslint-disable-next-line object-curly-newline
import { mdiAccount, mdiAlertCircleOutline, mdiDeleteOutline, mdiDotsVertical } from '@mdi/js'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import useVuetify from '@core/utils/vuetify'
import { useToast } from 'vue-toastification/composition'
import usePopupWithHash from '@/composables/usePopupWithHash'

export default {
  setup() {
    const { rootThemeClasses } = useVuetify()
    const eventsList = ref([])
    const loading = ref(true)
    const toast = useToast()
    const { isPopupOpen: deleteQA } = usePopupWithHash('delete-guests')

    const showToast = msg => toast.error(i18n.t(msg))
    const getEvents = () => {
      store
        .dispatch('fetchEvents')
        .then(res => {
          if (!res) {
            return
          }

          eventsList.value = res.data.map(event => ({
            ...event,
            image: event.image
              ? `${process.env.VUE_APP_LOCAL_HOST}event/images/${event.image}`
              : require('@/defaultImage/image.jpg'),
          }))
        })
        .finally(() => {
          loading.value = false
        })
    }
    getEvents()

    const delEvent = eventId => {
      deleteQA.value = false
      loading.value = true
      store
        .dispatch('deleteEvent', { id: eventId })
        .then(res => {
          if (res.data) {
            getEvents()
          }
        })
        .catch(error => {
          if (error.response.data.message === 'event_is_paid') {
            showToast('cantDeletePaidEvent')
          } else {
            showToast('errorManageEvents')
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    const getEventDetails = eventId => {
      router.push({ name: 'home-page', params: { id: eventId } })
    }

    return {
      rootThemeClasses,
      getEvents,
      eventsList,
      loading,
      delEvent,
      getEventDetails,
      deleteQA,

      icons: {
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiAlertCircleOutline,
        mdiAccount,
      },
    }
  },
}
</script>

<style scoped>
.verticalDots {
  position: absolute;
  z-index: 10;
}
.verticalDots:hover {
  background: #bdbdbd;
}
</style>
